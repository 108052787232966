import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  CircularProgress, Typography, TextField, Box, Container, Pagination, Card, CardContent,
  Avatar, FormGroup, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { SaveAlt as SaveAltIcon } from '@mui/icons-material';
import { API_BASE_URL } from '../constants/APIConstants';
import * as XLSX from 'xlsx';
import { auth } from '../firebase';
import { format, parseISO } from 'date-fns';
import AccountDetails from './AccountDetails'; // Import the modified AccountDetails component

// Helper functions (formatDate, convertToTitleCase) remain the same:
export const formatDate = (isoDate) => {
  if (!isoDate) return 'N/A';
  try {
    const cleanDate = isoDate.split('[')[0];
    return format(parseISO(cleanDate), 'MMM dd, yyyy h:mm a') + ' EST';
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid Date';
  }
};

const convertToTitleCase = (camelCaseString) => {
  if (!camelCaseString || typeof camelCaseString !== 'string') {
    return '';
  }
  return camelCaseString
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const WaitlistDashboard = () => {
  const [currentTab, setCurrentTab] = useState('pending');
  const [tableData, setTableData] = useState([]);
  const [tabCounts, setTabCounts] = useState({ pending: 0, approved: 0, rejected: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const itemsPerPage = 10;

  // State for filter checkboxes
  const [filters, setFilters] = useState({
    portfolioImages: null,
    isEmailVerified: null,
    hasDescription: null,
    socialMediaLinked: null
  });

  // New state to handle the account modal
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);

  useEffect(() => {
    const loadCountsFromLocalStorage = () => {
      const pendingCount = localStorage.getItem('pendingCount');
      const approvedCount = localStorage.getItem('approvedCount');
      const rejectedCount = localStorage.getItem('rejectedCount');
      setTabCounts({
        pending: pendingCount ? parseInt(pendingCount) : 0,
        approved: approvedCount ? parseInt(approvedCount) : 0,
        rejected: rejectedCount ? parseInt(rejectedCount) : 0,
      });
    };
    loadCountsFromLocalStorage();
    fetchData(currentTab);
  }, [currentTab]);

  const handleFilterChange = (filterName, value) => {
    console.log('Filter changed:', filterName, value);
    setFilters((prev) => ({
      ...prev,
      [filterName]: value === prev[filterName] ? null : value
    }));
    setCurrentPage(1);
  };

  // Instead of navigating, open the modal with the selected account data
  const handleViewProfile = (accountId) => {
    const account = tableData.find((account) => account.accountId === accountId);
    if (account) {
      setSelectedAccount(account);
      setIsAccountModalOpen(true);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.clear();
      // Navigation removed for this example
    } catch (error) {
      console.error('Error during logout:', error);
      alert('An error occurred while logging out. Please try again.');
    }
  };

  const fetchData = async (status) => {
    setLoading(true);
    const apiUrl = `${API_BASE_URL}/api/admin/getUnVerifiedAccounts?status=${status}`;
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(apiUrl, { headers: { Authorization: `Bearer ${token}` } });
      console.log('API Response:', response.data);
      
      if (response.data.length === 0) {
        console.warn("API returned no data or unexpected format:", response.data);
        setTableData([]);
        return;
      }

      const transformedData = response.data.map((item) => ({
        accountId: item.accountDetails?.accountId || 'N/A',
        name: item.accountDetails?.accountName || 'N/A',
        email: item.accountDetails?.accountEmail || 'N/A',
        contact: item.accountDetails?.accountPhone || 'N/A',
        portfolioImages: item.portfolioCollections?.some(
          (collection) => Array.isArray(collection.portfolioDocuments) && collection.portfolioDocuments.length > 0
        )
          ? 'Yes'
          : 'No',
        isEmailVerified: item.emailVerified,
        accountDescription: item.accountDetails?.accountDescription || 'N/A',
        socialMediaLinked: item.accountDetails?.socialMediaLinked,
        accountCreatedAt: formatDate(item.accountDetails?.accountCreatedAt),
        accountProfileURL: item.accountDetails?.accountProfileURL || 'No',
        accountCategory: convertToTitleCase(item.accountDetails?.accountCategory) || 'No',
        accountSubCategory: convertToTitleCase(item.accountDetails?.accountSubCategory) || 'No',
        Profile: item.accountDetails?.accountProfileURL || 'N/A', 
        status: item.status || 'pending', // Assuming a status field exists
        accountRating: item.accountDetails?.accountRating || '0', // Assuming a rating field exists
        accountTotalRatings: item.accountDetails?.accountTotalRatings || '0', // Assuming a total rating field exists
        // ... include any other fields needed for AccountDetails
      }));
      console.log('Transformed Data:', transformedData);
      setTableData(transformedData);
      setTabCounts((prevCounts) => {
        const updatedCounts = { ...prevCounts, [status]: transformedData.length };
        localStorage.setItem(`${status}Count`, transformedData.length);
        return updatedCounts;
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = () => {
    const filteredData = tableData.map(({ accountId, accountProfileURL, ...rest }) => rest);
    const headers = Object.keys(filteredData[0] || {}).map(convertToTitleCase);
    const dataWithHeaders = [headers, ...filteredData.map(Object.values)];
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Waitlist');
    XLSX.writeFile(workbook, 'WaitlistData.xlsx');
  };

  useEffect(() => {
    const cachedData = localStorage.getItem(`waitlistData-${currentTab}`);
    if (cachedData) {
      setTableData(JSON.parse(cachedData));
    } else {
      fetchData(currentTab);
    }
  }, [currentTab]);

  const filteredData = tableData.filter((row) => {
    const searchTerm = searchText.toLowerCase();
    const matchesSearch = (
      (row.name && row.name.toLowerCase().includes(searchTerm)) ||
      (row.email && row.email.toLowerCase().includes(searchTerm)) ||
      (row.contact && row.contact.toLowerCase().includes(searchTerm)) ||
      (row.accountCategory && row.accountCategory.toLowerCase().includes(searchTerm)) ||
      (row.accountSubCategory && row.accountSubCategory.toLowerCase().includes(searchTerm))
    );
    const matchesFilters = (
      (filters.portfolioImages === null || row.portfolioImages === (filters.portfolioImages ? 'Yes' : 'No')) &&
      (filters.isEmailVerified === null || row.isEmailVerified === filters.isEmailVerified) &&
      (filters.hasDescription === null || (row.accountDescription !== 'N/A') === filters.hasDescription) &&
      (filters.socialMediaLinked === null || row.socialMediaLinked === filters.socialMediaLinked)
    );
    return matchesSearch && matchesFilters;
  });

  const handleRefresh = () => {
    fetchData(currentTab);
  };

  const currentPageData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <Container maxWidth={false} style={{ marginTop: '150px', padding: '0 20px', width: '100%' }}>
      {/* Status Cards */}
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" gap="20px" style={{ width: '100%' }}>
        {['pending', 'approved', 'rejected'].map((status) => {
          const getCardStyles = () => {
            switch (status) {
              case 'pending':
                return { bgColor: '#ffecb3', hoverColor: '#ffd54f' };
              case 'approved':
                return { bgColor: '#c8e6c9', hoverColor: '#81c784' };
              case 'rejected':
                return { bgColor: '#ffcdd2', hoverColor: '#e57373' };
              default:
                return { bgColor: '#f0f0f0', hoverColor: '#e0e0e0' };
            }
          };
          const { bgColor, hoverColor } = getCardStyles();
          return (
            <Card
              key={status}
              onClick={() => setCurrentTab(status)}
              style={{
                cursor: 'pointer',
                flex: 1,
                border: currentTab === status ? '2px solid #1976d2' : '1px solid #ccc',
                backgroundColor: bgColor,
                borderRadius: '15px',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
                e.currentTarget.style.boxShadow = '0px 8px 20px rgba(0, 0, 0, 0.2)';
                e.currentTarget.style.backgroundColor = hoverColor;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.1)';
                e.currentTarget.style.backgroundColor = bgColor;
              }}
            >
              <CardContent>
                <Typography variant="h6" align="center" style={{ color: '#0f1e35', fontWeight: 'bold' }}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </Typography>
                <Typography variant="h4" align="center" style={{ color: '#0f1e35', fontWeight: 'bold' }}>
                  {tabCounts[status] || 0}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Box>

      {/* Search, Refresh and Export Buttons */}
      <Box display="flex" justifyContent="flex-start" alignItems="center" gap="20px" mb={3} mt={2} style={{ width: '100%' }}>
        <Box style={{ display: 'flex', alignItems: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '25px', backgroundColor: '#fff', width: '50%', padding: '5px 10px' }}>
          <TextField
            variant="outlined"
            placeholder="Search by Name, Email, Contact, Category or Sub Category"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <span style={{ marginRight: '8px', color: '#999', display: 'flex', alignItems: 'center' }}>
                  <i className="fas fa-search" style={{ fontSize: '18px' }}></i>
                </span>
              ),
            }}
            style={{ width: '100%', backgroundColor: '#fff', borderRadius: '25px' }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '25px',
                '& fieldset': { border: 'none' },
                '&:hover fieldset': { border: 'none' },
                '&.Mui-focused fieldset': { border: 'none' },
              },
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          style={{ flexShrink: 0, padding: '10px 20px', fontWeight: 'bold', backgroundColor: '#004080', color: '#fff', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)' }}
          onClick={handleRefresh}
        >
          Refresh
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<SaveAltIcon />}
          onClick={exportToExcel}
          style={{ flexShrink: 0, padding: '10px 20px', backgroundColor: '#1976d2', color: '#fff', fontWeight: 'bold', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)' }}
        >
          Export to Excel
        </Button>
      </Box>

      {/* Filter Checkboxes */}
      <Box mb={2} p={2} bgcolor="#f5f5f5" borderRadius={1}>
        <Typography variant="h6" gutterBottom>Filters</Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.portfolioImages === true}
                onChange={(e) => handleFilterChange('portfolioImages', e.target.checked)}
              />
            }
            label="Has Portfolio Images"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.isEmailVerified === true}
                onChange={(e) => handleFilterChange('isEmailVerified', e.target.checked)}
              />
            }
            label="Email Verified"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.hasDescription === true}
                onChange={(e) => handleFilterChange('hasDescription', e.target.checked)}
              />
            }
            label="Has Description"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.socialMediaLinked === true}
                onChange={(e) => handleFilterChange('socialMediaLinked', e.target.checked)}
              />
            }
            label="Social Media Linked"
          />
        </FormGroup>
      </Box>

      {/* Data Table */}
      <TableContainer component={Paper} style={{ marginTop: '20px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', borderRadius: '10px' }}>
        <Table style={{ borderCollapse: 'separate', borderSpacing: '0' }}>
          <TableHead>
            <TableRow>
              {[
                'Created At',
                'Profile',
                'Name',
                'Email',
                'Category',
                'Sub Category',
                'Rating',
                'Phone',
                'Portfolio Images',
                'Email Verified',
                'Description',
                'Social Media Linked',
                'Actions',
              ].map((header, index) => (
                <TableCell key={index} style={{ fontWeight: 'bold', borderBottom: '1px solid #ccc', backgroundColor: '#d4d4d4' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : currentPageData.length > 0 ? (
              currentPageData.map((row) => (
                <TableRow
                  key={row.accountId}
                  style={{ transition: 'background-color 0.3s ease, box-shadow 0.3s ease', cursor: 'pointer' }}
                  onClick={() => handleViewProfile(row.accountId)}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f5f5f5')}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#fff')}
                >
                  <TableCell style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>
                    {row.accountCreatedAt}
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>
                    <Avatar
                      src={row.accountProfileURL}
                      alt="Profile"
                      style={{ width: '50px', height: '50px', margin: '0 auto', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)' }}
                    />
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>{row.name}</TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>{row.email}</TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>{row.accountCategory}</TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>{row.accountSubCategory}</TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>{row.accountRating} ({row.accountTotalRatings})</TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>{row.contact}</TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>{row.portfolioImages}</TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>
                    {row.isEmailVerified ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>{row.accountDescription}</TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>
                    {row.socialMediaLinked ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell onClick={(e) => e.stopPropagation()}>
                    <Button variant="contained" color="primary" onClick={() => handleViewProfile(row.accountId)}>
                      View Profile
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12} align="center">No records found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination count={totalPages} page={currentPage} onChange={(_, page) => setCurrentPage(page)} color="primary" />
      </Box>

      {/* Dialog for Account Details */}
      <Dialog open={isAccountModalOpen} onClose={() => setIsAccountModalOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>Account Profile</DialogTitle>
        <DialogContent dividers>
          {selectedAccount ? (
            <AccountDetails account={selectedAccount} onClose={() => setIsAccountModalOpen(false)} />
          ) : (
            <Typography>No account data available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAccountModalOpen(false)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default WaitlistDashboard;

// const convertToTitleCase = (camelCaseString) => {
//   if (!camelCaseString || typeof camelCaseString !== 'string') {
//     return ''; // Return an empty string if the input is not valid
//   }
//   return camelCaseString
//     .replace(/([a-z])([A-Z])/g, '$1 $2') // Add a space before each uppercase letter
//     .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
// };



// export const formatDate = (isoDate) => {
//   if (!isoDate) return 'N/A';
//   try {
//     const cleanDate = isoDate.split('[')[0]; // Remove timezone if present
//     return format(parseISO(cleanDate), 'MMM dd, yyyy h:mm a') + ' EST'; // Format: Nov 13, 2024 8:51 PM EST
//   } catch (error) {
//     console.error('Error formatting date:', error);
//     return 'Invalid Date';
//   }
// };